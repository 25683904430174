/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SOInsertRequestDto
 */
export interface SOInsertRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'codiceInca'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'denominazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'declaratoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'dataInizioValidita'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'tipo'?: SOInsertRequestDtoTipoEnum;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'stato'?: SOInsertRequestDtoStatoEnum;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'dipartimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'sezione'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'email1'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'email2'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'cap'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'pec1'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'pec2'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'telefono1'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'telefono2'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'indirizzo'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'dataInserimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'citta'?: string;
    /**
     * 
     * @type {string}
     * @memberof SOInsertRequestDto
     */
    'tipoRichiesta'?: SOInsertRequestDtoTipoRichiestaEnum;
    /**
     * 
     * @type {number}
     * @memberof SOInsertRequestDto
     */
    'idFile'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SOInsertRequestDto
     */
    'equiparata'?: boolean;
}

export const SOInsertRequestDtoTipoEnum = {
    Dipartimento: 'DIPARTIMENTO',
    Sezione: 'SEZIONE',
    Servizio: 'SERVIZIO'
} as const;

export type SOInsertRequestDtoTipoEnum = typeof SOInsertRequestDtoTipoEnum[keyof typeof SOInsertRequestDtoTipoEnum];
export const SOInsertRequestDtoStatoEnum = {
    Compilata: 'COMPILATA',
    Registrata: 'REGISTRATA',
    Censita: 'CENSITA',
    Cessata: 'CESSATA'
} as const;

export type SOInsertRequestDtoStatoEnum = typeof SOInsertRequestDtoStatoEnum[keyof typeof SOInsertRequestDtoStatoEnum];
export const SOInsertRequestDtoTipoRichiestaEnum = {
    Confirm: 'CONFIRM',
    Save: 'SAVE',
    Inoltra: 'INOLTRA'
} as const;

export type SOInsertRequestDtoTipoRichiestaEnum = typeof SOInsertRequestDtoTipoRichiestaEnum[keyof typeof SOInsertRequestDtoTipoRichiestaEnum];


