import { StrutturaOrganizzativaDto } from '../../services/ms-anagrafica-unica'
import { AREA_CPI_UFFICIO, CITTA, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, EMAIL, INDIRIZZO, PEC, TELEFONO, TELEFONO_2, TIPOLOGIA_STRUTTURA, UNITA_ORGANIZZATIVA, CAP, PEC_2, EMAIL_2 } from '../../pages/strutture-organizzative/struttureOrganizzativeConstants'
import DocumentView from '../common/document-view/DocumentView'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utility/formatDate'

export interface SODetailsForm {
    value: StrutturaOrganizzativaDto,
    fields: Array<string>,
    hiddenFields?: Array<string>
}

const StruttureOrganizzativeDetails = (strutturaOrganizzativa: SODetailsForm) => {

    const { t } = useTranslation();
    let soDipa, soSez;

    if (strutturaOrganizzativa.value.strutturaOrganizzativaPadre) {
        const soLiv1 = strutturaOrganizzativa.value.strutturaOrganizzativaPadre;

        switch (soLiv1.tipo) {
            case 'DIPARTIMENTO':
                soDipa = soLiv1;
                break;
            case 'SEZIONE':
                soSez = soLiv1;
                break
            default:
                break;
        }

        if (soLiv1.strutturaOrganizzativaPadre) {
            const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
            switch (soLiv2.tipo) {
                case 'DIPARTIMENTO':
                    soDipa = soLiv2;
                    break;
                case 'SEZIONE':
                    soSez = soLiv2;
                    break
                default:
                    break;
            }
        }
    }

    const showField = (field : string, nome: string) => {
        let obj = Object.getOwnPropertyDescriptor(strutturaOrganizzativa.value, nome)
        return strutturaOrganizzativa.fields.indexOf(field) !== -1 && !!obj?.value ? obj?.value : ' - '
    }

    const isHiddenField = (field : string) => {
        return (strutturaOrganizzativa.hiddenFields?.indexOf(field) ?? -1) !== -1;
    }

    return (
            <div className="dettaglio-so row">
                <div className="col-sm-12">
                    <div className="info-box-light-so bg-light-gray">
                        <h5 className='pb-2 border-bottom font-weight-bold'>
                            Riepilogo
                        </h5>
                        <dl className={!isHiddenField(DENOMINAZIONE) && !isHiddenField(DECLARATORIA) ? "border-bottom py-2" : ""}>
                            <div className="row">
                                {!isHiddenField(DENOMINAZIONE) && (
                                    <dl className="col-12">
                                        <dt className='col-sm-12'>{DENOMINAZIONE}</dt>
                                        <dd className='col-sm-12'>{showField(DENOMINAZIONE, "descrizioneBreve")}</dd>
                                    </dl>
                                )}
                                {!isHiddenField(DECLARATORIA) && (
                                    <dl className="col-12">
                                        <dt className='col-sm-12'>{DECLARATORIA}</dt>
                                        <dd className='col-sm-12'>{showField(DECLARATORIA, "descrizione")}</dd>
                                    </dl>
                                )}
                            </div>
                        </dl>
                        <dl className={strutturaOrganizzativa?.value.documenti?.length! > 0 ? 'border-bottom py-2' : 'py-2'}>
                            <div className="row">
                                {!isHiddenField(DATA_INIZIO_VALIDITA) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{DATA_INIZIO_VALIDITA}</dt>
                                        <dd className='col-sm-12'>{showField(DATA_INIZIO_VALIDITA, "dataInizioValidita")}</dd>
                                    </div>
                                )}
                                
                                {!isHiddenField(TIPOLOGIA_STRUTTURA) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{TIPOLOGIA_STRUTTURA}</dt>
                                        <dd className='col-sm-12'>{showField(TIPOLOGIA_STRUTTURA, "tipoStrutturaDescrizione").toUpperCase()}</dd>
                                    </div>
                                )}
                                
                                {!isHiddenField(UNITA_ORGANIZZATIVA) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{UNITA_ORGANIZZATIVA}</dt>
                                        <dd className='col-sm-12'>{soDipa?.descrizioneBreve?.toUpperCase() ?? '-'}</dd>
                                        {/* <dd className='col-sm-12'>{showField(DIPARTIMENTO, "soDipa")}</dd> */}
                                    </div>
                                )}
                                
                                {!isHiddenField(UNITA_ORGANIZZATIVA) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{AREA_CPI_UFFICIO}</dt>
                                        <dd className='col-sm-12'>{soSez?.descrizioneBreve?.toUpperCase()?? '-'}</dd>
                                        {/* <dd className='col-sm-12'>{showField(SEZIONE, "soSez")}</dd> */}
                                    </div>
                                )}

                                {!isHiddenField(EMAIL) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{EMAIL}</dt>
                                        <dd className='col-sm-12'>{showField(EMAIL, "email1")}</dd>
                                    </div>
                                )}

                                {!isHiddenField(EMAIL_2) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{EMAIL_2}</dt>
                                        <dd className='col-sm-12'>{showField(EMAIL_2, "email2")}</dd>
                                    </div>
                                )}

                                {!isHiddenField(PEC) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{PEC}</dt>
                                        <dd className='col-sm-12'>{showField(PEC, "pec1")}</dd>
                                    </div>
                                )}

                                {!isHiddenField(PEC_2) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{PEC_2}</dt>
                                        <dd className='col-sm-12'>{showField(PEC_2, "pec2")}</dd>
                                    </div>
                                )}
                                
                                {!isHiddenField(TELEFONO) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{TELEFONO}</dt>
                                        <dd className='col-sm-12'>{showField(TELEFONO, "telefono1")}</dd>
                                    </div>
                                )}
                                
                                {!isHiddenField(TELEFONO_2) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{TELEFONO_2}</dt>
                                        <dd className='col-sm-12'>{showField(TELEFONO_2, "telefono2")}</dd>
                                    </div>
                                )}

                                {!isHiddenField(INDIRIZZO) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{INDIRIZZO}</dt>
                                        <dd className='col-sm-12'>{showField(INDIRIZZO, "indirizzo")}</dd>
                                    </div>
                                )}
                                
                                {!isHiddenField(CITTA) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{CITTA}</dt>
                                        <dd className='col-sm-12'>{showField(CITTA, "citta")}</dd>
                                    </div>
                                )}
                                {!isHiddenField(CAP) && (
                                    <div className="col-md-6 col-xl-4">
                                        <dt className='col-sm-12'>{CAP}</dt>
                                        <dd className='col-sm-12'>{showField(CAP, "cap")}</dd>
                                    </div>
                                )}
                            </div>
                        </dl>
                        <dl className="col-sm-12">
                            {(strutturaOrganizzativa?.value.documenti?.length! > 0) && 
                                <div className="documentView pt-2">
                                    <h4>Documenti caricati</h4>
                                    <div className="form-row">
                                        {strutturaOrganizzativa?.value.documenti?.map((value, index) => (
                                            <div className="col-4" key={index}>
                                               <DocumentView documentName={ t(`tipo-documento.${value.tipo!}`) + ' (' + formatDate(value.id?.documento?.dataInserimento || '') + ')'} 
                                                            documento={value.id?.documento} download />
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            }
                        </dl>
                    </div>
                </div>
            </div >
    )
}

export default StruttureOrganizzativeDetails