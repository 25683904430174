/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DipendenteRegionaleDto } from './dipendente-regionale-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PosizioneOrganizzativaOrganigrammaDto } from './posizione-organizzativa-organigramma-dto';

/**
 * 
 * @export
 * @interface StrutturaOrganizzativaOrganigrammaDto
 */
export interface StrutturaOrganizzativaOrganigrammaDto {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'descrizione'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'descrizioneBreve'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'dataInizioValidita'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceIncaricoPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'descrizioneBrevePadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'responsabile'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'pec1'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'email1'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'pec2'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'email2'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'telefono1'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'telefono2'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'indirizzo'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'cap'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'citta'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceUfficioDetermine'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceUfficioDelibere'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceIpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceBilancio'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceDipartimentoRagioneria'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'codiceSezioneRagioneria'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'tipo'?: StrutturaOrganizzativaOrganigrammaDtoTipoEnum;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'stato'?: StrutturaOrganizzativaOrganigrammaDtoStatoEnum;
    /**
     * 
     * @type {Array<StrutturaOrganizzativaOrganigrammaDto>}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'struttureOrganizzativeFiglie'?: Array<StrutturaOrganizzativaOrganigrammaDto>;
    /**
     * 
     * @type {Array<PosizioneOrganizzativaOrganigrammaDto>}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'posizioniOrganizzative'?: Array<PosizioneOrganizzativaOrganigrammaDto>;
    /**
     * 
     * @type {Array<DipendenteRegionaleDto>}
     * @memberof StrutturaOrganizzativaOrganigrammaDto
     */
    'dipendentiRegionali'?: Array<DipendenteRegionaleDto>;
}

export const StrutturaOrganizzativaOrganigrammaDtoTipoEnum = {
    Dipartimento: 'DIPARTIMENTO',
    Sezione: 'SEZIONE',
    Servizio: 'SERVIZIO'
} as const;

export type StrutturaOrganizzativaOrganigrammaDtoTipoEnum = typeof StrutturaOrganizzativaOrganigrammaDtoTipoEnum[keyof typeof StrutturaOrganizzativaOrganigrammaDtoTipoEnum];
export const StrutturaOrganizzativaOrganigrammaDtoStatoEnum = {
    Compilata: 'COMPILATA',
    Registrata: 'REGISTRATA',
    Censita: 'CENSITA',
    Cessata: 'CESSATA'
} as const;

export type StrutturaOrganizzativaOrganigrammaDtoStatoEnum = typeof StrutturaOrganizzativaOrganigrammaDtoStatoEnum[keyof typeof StrutturaOrganizzativaOrganigrammaDtoStatoEnum];


